<template>
  <div id="module2" class="row center">
    <div class="margX2"></div>
    <div class="col col-100 padding">
      <h2 class="heading_2 center">{{ title }}</h2>
      <p class="center heading_5 semibold">{{ subtitle }}</p>
      <div class="margX2"></div>
      <div class="row flex-envenly">
        <span
          v-for="(filter, index) in trueContent"
          :key="index"
          @click="addMotorToCompare(index)"
          :class="{ actif: filter.isActive }"
          class="col col-25 col btn_filter"
        >
          <picture
            ><img
              :src="
                'https://cdn2.jlrfrance.fr/uploads/10098/jaguar/' +
                content[index].infos.header.picto
              "
              :alt="filter.name"
          /></picture>
          <font class="heading_4 semibold">{{
            content[index].infos.header.titre
          }}</font>
          <span class="newCheckbox"></span>
        </span>
      </div>
      <div class="msg" style="margin-top: 0.8em" v-if="disclaimer == 1">
        * VEUILLEZ DÉSÉLECTIONNER UNE MOTORISATION POUR EN CHOISIR UNE AUTRE.
      </div>
    </div>
    <div class="margX2"></div>
    <div class="comparatorContainer">
      <Card v-if="content[0].isActive" :content="content[0]"></Card>
      <Card v-if="content[1].isActive" :content="content[1]"></Card>
      <Card v-if="content[2].isActive" :content="content[2]"></Card>
      <Card v-if="content[3].isActive" :content="content[3]"></Card>
      <div v-if="activeFilterLength < 3" class="comparatorCard empty">
        <div class="heading_4" v-html="content[4].texte"></div>
      </div>
      <div v-if="activeFilterLength < 2" class="comparatorCard empty">
        <div class="heading_4" v-html="content[4].texte"></div>
      </div>
      <div v-if="activeFilterLength < 1" class="comparatorCard empty">
        <div class="heading_4" v-html="content[4].texte"></div>
      </div>
    </div>
    <div class="cg_mentions center text_3 col-50 padding">
      *Sur PHEV et MHEV carte grise gratuite dans toutes les régions sauf Grand-Est, Bretagne, Nouvelle-Aquitaine, Pays de la Loire, DOM-TOM, Bourgogne-Franche-Comté (gratuite jusqu’en juillet 2023 puis payante), carte grise à -50% dans les Hauts-de-France et Centre-Val-de-Loire.
    </div>
  </div>
</template>
<script>
import Card from "./Card.vue";
export default {
  name: "Comparator",
  components: {
    Card,
  },
  props: ["recoMotors"],
  data() {
    return {
      title: "Comparez nos motorisations",
      subtitle: "Sélectionnez jusqu'à 3 technologies",
      disclaimer: 0,
      toCompare: this.recoMotors,
      activeFilterLength: 0,
      content: [
        {
          filters: true,
          isActive: false,
          name: "BEV",
          infos: {
            header: { picto: "BEV_ICON.svg", titre: "100% électrique" },
            criteres: [
              "Électrique",
              "Prise électrique : requise",
              "0 g",
              "Pas de malus<br>Carte grise gratuite sauf exception*<br>Pas de TVS<br>Amortissement exceptionnel",
              "Vignette verte",
              "Poids tractable : 750 kg",
            ],
            links: [
              "https://www.jaguar.fr/jaguar-range/i-pace/i-pace-au-quotidien.html",
              "",
            ],
          },
        },
        {
          filters: true,
          isActive: false,
          name: "PHEV",
          infos: {
            header: { picto: "PHEV_ICON.svg", titre: "Hybride électrique" },
            criteres: [
              "Électrique + essence",
              "Prise électrique : conseillée",
              "Entre 31 et 41 g",
              "Pas de malus<br>Pas de taxe poids (si l'autonomie 100% électrique est supérieure à 51&nbsp;km)<br>Carte grise gratuite sauf exception*<br>Pas de TVS<br>Plafond amortissement réhaussé",
              "Vignette 1",
              "Poids tractable : 1600 à 2000 kg",
            ],
            links: [
              "https://www.jaguar.fr/jaguar-range/e-pace/hybrid/index.html",
              "",
            ],
          },
        },
        {
          filters: true,
          isActive: false,
          name: "MHEV",
          infos: {
            header: { picto: "MHEV_ICON.svg", titre: "Hybride" },
            criteres: [
              "Diesel",
              "Prise électrique : non",
              "Entre 127 et 206 g",
              "Malus<br>Taxe poids<br>Carte grise gratuite sauf exception*<br>TVS",
              "Vignette 2",
              "Poids tractable : 1800 à 2400 kg",
            ],
            links: [
              "https://www.jaguar.fr/jaguar-range/e-pace/index.html#661-807108",
              "",
            ],
          },
        },
        {
          filters: true,
          isActive: false,
          name: "E85",
          infos: {
            header: { picto: "FLEXFUEL_ICON.svg", titre: "Flexfuel" },
            criteres: [
              "E85 / Essence",
              "Prise électrique : non",
              "Entre 196 et 218 g",
              "Pas de malus<br>Taxe poids<br>Carte grise gratuite sauf exception*<br>TVS",
              "Vignette 1",
              "Poids tractable : 1800 kg",
            ],
            links: ["https://www.jaguar.fr/flexfuel/index.html", ""],
          },
        },
        {
          filters: false,
          isActive: false,
          name: "Non défini",
          texte: "Comparer<br>une nouvelle motorisation",
        },
      ],
    };
  },
  methods: {
    addMotorToCompare(value) {
      if (this.activeFilterLength >= 3) {
        if (this.content[value].isActive === true) {
          this.content[value].isActive = !this.content[value].isActive;
          this.activeFilterLength--;
          this.disclaimer = 0;
        } else {
          this.disclaimer = 1;
        }
      } else {
        this.content[value].isActive = !this.content[value].isActive;
        if (this.content[value].isActive === true) {
          this.activeFilterLength++;
        } else {
          this.activeFilterLength--;
        }
      }
    },
    removeMotorToCompare(value) {
      this.content[value].isActive = !this.content[value].isActive;
      this.activeFilterLength--;
    },
    resetComparator() {
      const a = this.content;
      a.forEach((element) => {
        element.isActive = false;
        this.activeFilterLength = 0;
      });
    },
    comparisonAfterQuiz() {
      this.resetComparator();
      const a = this.toCompare;
      a.forEach((element) => {
        this.content[element].isActive = true;
        this.activeFilterLength++;
      });
    },
  },
  computed: {
    trueContent() {
      const a = this.content.filter((item) => item.filters == true);
      return a;
    },
  },
  watch: {
    recoMotors: function () {
      this.comparisonAfterQuiz();
    },
  },
  mounted() {
    this.comparisonAfterQuiz();
  },
};
</script>