<template>
  <div id="app" class="container">
    <div class="row center">
      <Quiz></Quiz>
    </div>
    <Comparator :recoMotors="reco"></Comparator>
  </div>
</template>

<script>
import Comparator from "./components/Comparator.vue";
import Quiz from "./components/Quiz.vue";
export default {
  name: "App",
  components: {
    Quiz,
    Comparator,
  },
  data() {
    return {
      reco: [0, 1],
    };
  },
};
</script>
<style>
@font-face {
  font-family: "jaguar-bold_opp";
  src: url("https://cdn2.jlrfrance.fr/webfonts/jaguar-bold-webfont.eot"); /* IE9 Compat Modes */
  src: url("https://cdn2.jlrfrance.fr/webfonts/jaguar-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("https://cdn2.jlrfrance.fr/webfonts/jaguar-bold-webfont.woff")
      format("woff"),
    /* Modern Browsers */
      url("https://cdn2.jlrfrance.fr/webfonts/jaguar-bold-webfont.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "jaguar-regular_opp";
  src: url("https://cdn2.jlrfrance.fr/webfonts/jaguar-regular-webfont.eot"); /* IE9 Compat Modes */
  src: url("https://cdn2.jlrfrance.fr/webfonts/jaguar-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("https://cdn2.jlrfrance.fr/webfonts/jaguar-regular-webfont.woff")
      format("woff"),
    /* Modern Browsers */
      url("https://cdn2.jlrfrance.fr/webfonts/jaguar-regular-webfont.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "JLR_Proxima_Nova_bold_opp";
  src: url("https://cdn2.jlrfrance.fr/webfonts/ProximaNova-Bold.woff")
    format("woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "JLR_Proxima_Nova_semibold_opp";
  src: url("https://cdn2.jlrfrance.fr/webfonts/ProximaNova-Semibold.woff")
    format("woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "JLR_Proxima_Nova_regular_opp";
  src: url("https://cdn2.jlrfrance.fr/webfonts/ProximaNova-Regular.woff")
    format("woff");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "JLR_Proxima_Nova_regularItalic_opp";
  src: url("https://cdn2.jlrfrance.fr/webfonts/ProximaNova-RegularItalic.woff")
    format("woff");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "JLR_Proxima_Nova_light_opp";
  src: url("https://cdn2.jlrfrance.fr/webfonts/ProximaNova-Light.woff")
    format("woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "icons-jaguar";
  src: url("https://cdn2.jlrfrance.fr/webfonts/pic-jaguar.eot?8tynwf");
  src: url("https://cdn2.jlrfrance.fr/webfonts/pic-jaguar.eot?8tynwf#iefix")
      format("embedded-opentype"),
    url("https://cdn2.jlrfrance.fr/webfonts/pic-jaguar.ttf?8tynwf")
      format("truetype"),
    url("https://cdn2.jlrfrance.fr/webfonts/pic-jaguar.woff?8tynwf")
      format("woff"),
    url("https://cdn2.jlrfrance.fr/webfonts/pic-jaguar.svg?8tynwf#icomoon")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@import url("https://cdn2.jlrfrance.fr/spe/10098/jaguar2.css");

/* Override CSS Buttons */
#app .btn_1, #app input[type='submit'], #app select[type='submit'], #app .cardOffre .card_content .content-btn, #app .cardVehiculeApproved .card_content .content-btn, #app .cardVehiculeStock .card_content .content-btn, #app .cardVehiculeGamme .card_content .content-btn {
    background-color: #3c3c3b;
    color: #ffffff;
    border: 1px solid #3c3c3b;
}

</style>