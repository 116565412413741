<template>
  <div class="col col-90 col-m-70 col-l-60 padding" style="margin-bottom: 3em">
    <vue-slider
      v-model="value"
      v-bind="options"
      :data="data"
      :data-label="'name'"
      :data-value="'id'"
      @change="setRange"
    >
      <template v-slot:dot="{ focus }">
        <div :class="['custom-dot', { focus }]"></div>
      </template>
      <template v-slot:label="{ label }">
        <div :class="['vue-slider-mark-label', 'custom-label']">
          {{ label }}
        </div>
      </template>
    </vue-slider>
    <p class="valForMobile center">
      <span v-if="value == 0">{{ data[0].name }}</span>
      <span v-if="value == 1">{{ data[1].name }}</span>
      <span v-if="value == 2">{{ data[2].name }}</span>
      <span v-if="value == 3">{{ data[3].name }}</span>
    </p>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

export default {
  components: {
    VueSlider,
  },
  name: "Range",
  data() {
    return {
      value: 0,
      data: [
        { id: 0, name: "Moins de 50km" },
        { id: 1, name: "Entre 50 et 200km" },
        { id: 2, name: "Plus de 200km" },
        { id: 3, name: "Variable" },
      ],
      options: {
        stepStyle: {
          backgroundColor: "#DEDEDE",
          height: "16px",
          width: "2px",
          top: "-6px",
          boxShadow: "none",
          borderRadius: "0",
        },
        stepActiveStyle: {
          backgroundColor: "var(--main-color)",
        },
        processStyle: { backgroundColor: "var(--main-color)" },
        tooltip: "none",
        tooltipStyle: {
          backgroundColor: "var(--main-color)",
          borderColor: "var(--main-color)",
        },
        railStyle: { backgroundColor: "#DEDEDE", height: "2px" },
      },
    };
  },
  methods: {
    setRange() {
      this.$parent.Quiz[1].value = this.value;
    },
  },
};
</script>
<style lang="scss" scoped>
.vue-slider,
.vue-slider-rail {
  height: 2px !important;
}
.custom-label {
  font-family: "JLR_Proxima_Nova_regular_opp", Helvetica, Arial, "sans-serif";
  display: none;
}
.valForMobile {
  display: block;
  margin-top: 26px;
}
.custom-dot {
  border-radius: 50%;
  background-color: var(--main-color);
  transition: all 0.3s;
  width: 46px;
  height: 46px;
  border: 6px solid var(--back);
  margin-top: -16px;
  margin-left: -17px;
  background-image: url("https://cdn2.jlrfrance.fr/uploads/10098/jaguar/cursor-slider.png");
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: center center;
}
@media screen and (min-width: 992px) {
  .custom-label {
    display: block;
    margin-top: 26px !important;
  }
  .valForMobile {
    display: none;
  }
}
</style>