<template>
  <div class="col col-100 nopad">
    <div id="module1" class="">
      <section
        id="step-start"
        v-if="step == 0"
        class="step"
        style="
          background-image: url('https://cdn2.jlrfrance.fr/uploads/10098/jaguar-quiz.png');
        "
      >
        <div>
          <h2 class="heading_2" style="margin-bottom: 1em !important">
            Découvrez la motorisation qui vous correspond
          </h2>
          <p class="text_1">
            Parlez-nous de votre style de vie ou de conduite.<br />Et découvrez
            si votre véhicule idéal sera 100% électrique, hybride, hybride
            éléctrique ou hybride Flexfuel.
          </p>
          <button @click="start" class="btn_1">Démarrez le test</button>
        </div>
      </section>
      <div v-if="step > 0" class="background-1 w100">
        <div class="w100 center" v-if="step <= totauxSteps">
          <span id="stepIndicatorContainer">
            <div class="indicator" :style="indicator"></div>
          </span>
          <p class="heading_5" v-if="step <= totauxSteps">
            {{ step }} sur {{ totauxSteps }}
          </p>
          <p class="heading_5" v-else>résultats</p>
        </div>
        <section v-if="step == 1" class="step">
          <h2 class="heading_2" v-html="questions[0]"></h2>
          <div class="row w100 stretch">
            <div class="col col-100 col-m-60 column questions">
              <label for="answer1-b" class="labelBlock text_1"
                >Je suis professionnel<input
                  type="radio"
                  id="answer1-b"
                  name="answer1"
                  value="1"
                  class="newCheckbox"
                  v-model="Quiz[0].value"
              /></label>
              <label for="answer1-a" class="labelBlock text_1"
                >Je suis particulier
                <input
                  type="radio"
                  id="answer1-a"
                  name="answer1"
                  value="0"
                  class="newCheckbox"
                  v-model="Quiz[0].value"
              /></label>
            </div>
            <div class="col col-100 col-m-40">
              <img
                src="https://cdn2.jlrfrance.fr/uploads/10098/jaguar/QUIZ-1.png"
                alt=""
              />
            </div>
          </div>
        </section>
        <section v-if="step == 2" class="step">
          <h2 class="heading_2" v-html="questions[1]"></h2>
          <div class="column" style="align-items: center">
            <div class="col col-30 col-m-20">
              <img
                src="https://cdn2.jlrfrance.fr/uploads/10098/jaguar/QUIZ-2.png"
                alt=""
                style="margin-bottom: 3em"
              />
            </div>
            <Range></Range>
          </div>
        </section>
        <section v-if="step == 3" class="step">
          <h2 class="heading_2" v-html="questions[2]"></h2>
          <div class="row w100 stretch">
            <div class="col col-100 col-m-60 column questions">
              <label for="answer3-a" class="labelBlock text_1"
                >oui<input
                  type="radio"
                  id="answer3-a"
                  name="answer3"
                  value="0"
                  class="newCheckbox"
                  v-model="Quiz[2].value"
              /></label>
              <label for="answer3-b" class="labelBlock text_1"
                >non<input
                  type="radio"
                  id="answer3-b"
                  name="answer3"
                  value="1"
                  class="newCheckbox"
                  v-model="Quiz[2].value"
              /></label>
            </div>
            <div class="col col-100 col-m-40">
              <img
                src="https://cdn2.jlrfrance.fr/uploads/10098/jaguar/QUIZ-3.png"
                alt=""
              />
            </div>
          </div>
        </section>
        <section v-if="step == 4" class="step">
          <h2 class="heading_2" v-html="questions[3]"></h2>
          <div class="row w100 stretch">
            <div class="col col-100 col-m-60 column questions">
              <label for="answer4-a" class="labelBlock text_1"
                >Oui, je dois souvent circuler dans des zones à faibles
                émissions
                <input
                  type="radio"
                  id="answer4-a"
                  name="answer4"
                  value="0"
                  class="newCheckbox"
                  v-model="Quiz[3].value"
              /></label>
              <label for="answer4-b" class="labelBlock text_1"
                >Non, pas particulièrement<input
                  type="radio"
                  id="answer4-b"
                  name="answer4"
                  value="1"
                  class="newCheckbox"
                  v-model="Quiz[3].value"
              /></label>
            </div>
            <div class="col col-100 col-m-40">
              <img
                src="https://cdn2.jlrfrance.fr/uploads/10098/jaguar/QUIZ-4.png"
                alt=""
              />
            </div>
          </div>
        </section>
        <section v-if="step == 5" class="step">
          <h2 class="heading_2" v-html="questions[4]"></h2>
          <div class="row w100 stretch">
            <div class="col col-100 col-m-60 column questions">
              <label for="answer5-a" class="labelBlock text_1"
                >oui<input
                  type="radio"
                  id="answer5-a"
                  name="answer5"
                  value="0"
                  class="newCheckbox"
                  v-model="Quiz[4].value"
              /></label>
              <label for="answer5-b" class="labelBlock text_1"
                >Non<input
                  type="radio"
                  id="answer5-b"
                  name="answer5"
                  value="1"
                  class="newCheckbox"
                  v-model="Quiz[4].value"
              /></label>
            </div>
            <div class="col col-100 col-m-40">
              <img
                src="https://cdn2.jlrfrance.fr/uploads/10098/jaguar/QUIZ-5.png"
                alt=""
              />
            </div>
          </div>
        </section>
        <section v-if="step == 6" class="step">
          <h2 class="heading_2" v-html="questions[5]"></h2>
          <div class="row w100 stretch">
            <div class="col col-100 col-m-60 column questions">
              <label for="answer6-a" class="labelBlock text_1"
                >Oui, pour le travail ou les loisirs<input
                  type="radio"
                  id="answer6-a"
                  name="answer6"
                  value="0"
                  class="newCheckbox"
                  v-model="Quiz[5].value"
              /></label>
              <label for="answer6-b" class="labelBlock text_1"
                >Non, pas particulièrement<input
                  type="radio"
                  id="answer6-b"
                  name="answer6"
                  value="1"
                  class="newCheckbox"
                  v-model="Quiz[5].value"
              /></label>
            </div>
            <div class="col col-100 col-m-40">
              <img
                src="https://cdn2.jlrfrance.fr/uploads/10098/jaguar/QUIZ-6.png"
                alt=""
              />
            </div>
          </div>
        </section>
        <section id="result" v-if="step == 7" class="step">
          <h2 class="heading_2 title">{{ content[best1].result[0] }}</h2>
          <div class="margX2"></div>
          <div class="row">
            <div class="col col-50 col-m-33">
              <picture v-if="best1 == 0"
                ><img
                  src="https://cdn2.jlrfrance.fr/uploads/10098/jaguar/BEV_ICON.svg"
                  :alt="content[best1].type"
              /></picture>
              <picture v-if="best1 == 1"
                ><img
                  src="https://cdn2.jlrfrance.fr/uploads/10098/jaguar/PHEV_ICON.svg"
                  :alt="content[best1].type"
              /></picture>
              <picture v-if="best1 == 2"
                ><img
                  src="https://cdn2.jlrfrance.fr/uploads/10098/jaguar/MHEV_ICON.svg"
                  :alt="content[best1].type"
              /></picture>
              <picture v-if="best1 == 3"
                ><img
                  src="https://cdn2.jlrfrance.fr/uploads/10098/jaguar/FLEXFUEL_ICON.svg"
                  :alt="content[best1].type"
              /></picture>
            </div>
            <div class="col col-100 col-m-66 left">
              <p class="heading_3 strong" v-html="content[best1].result[1]"></p>
              <p v-html="content[best1].result[2]"></p>
              <a :href="content[best1].result[3]" class="btn_4" target="_blank"
                >En savoir plus sur cette motorisation</a
              ><br />
              <a href="#module2" class="btn_4" target="_self"
                >Comparez avec nos autres motorisations</a
              >
            </div>
          </div>
          <div class="row">
            <p
              class="heading_2 w100"
              v-html="content[best1].result[4]"
              style="margin-bottom: 0.5em !important"
            ></p>
            <div
              class="col col-100 col-m-50 col-l-33 col-xl-25 padding left"
              v-for="(vehicle, index) in content[best1].vehicles"
              :key="index"
            >
              <img
                :src="
                  'https://cdn2.jlrfrance.fr/uploads/10098' + vehicle.picture
                "
                :alt="vehicle.name"
                class="w100"
              />
              <h4 class="heading_3 strong">{{ vehicle.name }}</h4>
              <a :href="vehicle.link1" class="btn_4" target="_blank"
                >Découvrez ce modèle</a
              ><br />
              <a :href="vehicle.link2" class="btn_4" target="_blank"
                >Recevez une offre commerciale</a
              >
            </div>
          </div>
        </section>
        <div class="row flex-between btns" v-if="step > 0 && step < 7">
          <p class="msg" v-show="notif.error == 1">{{ notif.disclaimer }}</p>
          <button
            v-if="step > 0 && step < 7"
            @click="prevStep"
            class="btn_1 icon-toleft"
          >
            précédent
          </button>
          <button
            v-if="step < 6 && step > 0"
            @click="nextStep(step)"
            class="btn_1 icon-toright"
          >
            suivant
          </button>
          <button
            v-if="step == totauxSteps"
            @click="calcul(step)"
            class="btn_1"
          >
            voir les résultats
          </button>
        </div>
        <div class="row btns flex-end" v-if="step == 7">
          <button @click="retry" class="btn_1">recommencer</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Range from "./Range.vue";
export default {
  name: "Quiz",
  components: {
    Range,
  },
  data() {
    return {
      questions: [
        "Êtes-vous un client professionnel ou particulier&nbsp;?",
        "Combien de kilomètres parcourez-vous en moyenne par jour&nbsp;?",
        "Avez-vous accès ou possibilité d'accès à un point de recharge à votre domicile ou sur votre lieu de travail&nbsp;?",
        "La classification de la vignette Crit'Air est-elle importante pour vous&nbsp;?",
        "Effectuez-vous fréquemment de longs trajets autoroutiers&nbsp;?",
        "La capacité de remorquage est-elle importante pour vous&nbsp;?",
      ],
      overlay: 0,
      step: 0,
      totauxSteps: 6,
      reponses: [0, 0, 0, 0],
      best1: 0,
      best2: 0,
      notif: {
        error: 0,
        disclaimer: "* Veuillez sélectionner au moins une réponse.",
      },
      content: [
        {
          type: "BEV",
          result: [
            "Vous êtes prêt pour passer au 100% électrique",
            "100% electrique",
            "La Jaguar I-PACE comblera toutes vos attentes : SUV tout-électrique et sans aucune émission polluante en phase de roulage. Digne héritière des performances et technologies Jaguar, la I-PACE vous assure un plaisir de conduite incomparable. Et bénéficiez également de nombreux avantages fiscaux comme l'éxonération de la carte grise ou de la TVS pour les professionnels. Faites le choix du 100% électrique, 100% gagnant.",
            "https://www.jaguar.fr/jaguar-range/i-pace/i-pace-au-quotidien.html",
            "Démarrez votre voyage avec Jaguar",
          ],
          vehicles: [
            {
              name: "I-Pace",
              picture: "/jaguar/BEV_I-PACE.jpg",
              link1: "https://www.jaguar.fr/jaguar-range/i-pace/index.html",
              link2:
                "https://www2.contact-jaguar.fr/demande-de-cotation/i-pace?model=x590&formCode=jag-requestquote-fr-fr&tracking_source=jdx&tracking_medium=external-link&tracking_campaign=JDX_quote&tracking_content=quizz-electric",
            },
          ],
        },
        {
          type: "PHEV",
          result: [
            "C'est le moment de passer à l'hybride rechargeable",
            "L'HYBRIDE RECHARGEABLE (PHEV)",
            "En associant un moteur thermique à un moteur électrique pour une efficacité et des performances optimales, la technologie hybride rechargeable s'adapte à tous vos trajets. Optez pour le tout-électrique en ville ou passez en mode thermique pour vos déplacements plus longs.<br>Ne faites ainsi aucun compromis et bénéficiez en plus de nombreux avantages fiscaux.",
            "https://www.jaguar.fr/jaguar-range/e-pace/hybrid/index.html",
            "Démarrez votre voyage avec Jaguar",
          ],
          vehicles: [
            {
              name: "E-Pace",
              picture: "/jaguar/PHEV_E-PACE.jpg",
              link1:
                "https://www.jaguar.fr/jaguar-range/e-pace/hybrid/index.html",
              link2:
                "https://www2.contact-jaguar.fr/demande-de-cotation-2?formCode=jag-requestquote-fr-fr&variant=part_phev&tracking_source=jdx&tracking_medium=external-link&tracking_campaign=JDX_quote&tracking_content=quizz-phev",
            },
            {
              name: "F-Pace",
              picture: "/jaguar/PHEV_F-PACE.jpg",
              link1:
                "https://www.jaguar.fr/jaguar-range/f-pace/hybrid/index.html",
              link2:
                "https://www2.contact-jaguar.fr/demande-de-cotation-2?formCode=jag-requestquote-fr-fr&variant=part_phev&tracking_source=jdx&tracking_medium=external-link&tracking_campaign=JDX_quote&tracking_content=quizz-phev",
            },
          ],
        },
        {
          type: "MHEV",
          result: [
            "La technologie hybride est faite pour vous",
            "L'HYBRIDE (MHEV)",
            "Alliance d'un moteur thermique et d'une batterie, la technologie hybride récupère et stocke l'énergie habituellement perdue lors du freinage, et la réutilise intelligemment pour assister le moteur. Elle vous permet ainsi de réduire votre consommation de carburant et les émissions CO₂.<br>Aucune recharge n'est nécessaire, le système s'occupe de tout : il ne vous reste plus qu'à profiter du plaisir de prendre la route en toute sérénité.",
            "https://www.jaguar.fr/jaguar-range/e-pace/index.html#661-807108",
            "Démarrez votre voyage avec Jaguar",
          ],
          vehicles: [
            {
              name: "E-Pace",
              picture: "/jaguar/MHEV_E-PACE.jpg",
              link1: "https://www.jaguar.fr/jaguar-range/e-pace/index.html",
              link2:
                "https://www2.contact-jaguar.fr/demande-de-cotation/e-pace?model=x540&formCode=jag-requestquote-fr-fr&tracking_source=jdx&tracking_medium=external-link&tracking_campaign=JDX_quote&tracking_content=quizz-mhev",
            },
            {
              name: "F-Pace",
              picture: "/jaguar/MHEV_F-PACE.jpg",
              link1: "https://www.jaguar.fr/jaguar-range/f-pace/index.html",
              link2:
                "https://www2.contact-jaguar.fr/demande-de-cotation/f-pace?model=x761&formCode=jag-requestquote-fr-fr&tracking_source=jdx&tracking_medium=external-link&tracking_campaign=JDX_quote&tracking_content=quizz-mhev",
            },
            {
              name: "XE",
              picture: "/jaguar/MHEV_XE.jpg",
              link1: "https://www.jaguar.fr/jaguar-range/xe/index.html",
              link2:
                "https://www2.contact-jaguar.fr/demande-de-cotation/xe?model=x760&formCode=jag-requestquote-fr-fr&tracking_source=jdx&tracking_medium=external-link&tracking_campaign=JDX_quote&tracking_content=quizz-mhev",
            },
            {
              name: "XF",
              picture: "/jaguar/MHEV_XF.jpg",
              link1: "https://www.jaguar.fr/jaguar-range/xf/index.html",
              link2:
                "https://www2.contact-jaguar.fr/demande-de-cotation/xf?model=x260&formCode=jag-requestquote-fr-fr&tracking_source=jdx&tracking_medium=external-link&tracking_campaign=JDX_quote&tracking_content=quizz-mhev",
            },
          ],
        },
        {
          type: "E85",
          result: [
            "L'hybride Flexfuel est faite pour vous",
            "L'HYBRIDE FLEXFUEL",
            "La technologie hybride Flexfuel vous permet désormais de rouler au superéthanol E85. En plus de son tarif très avantageux, vous bénéficiez également de nombreux avantages fiscaux, comme l'éxonération de malus à l'achat. Notre technologie vous assure une grande flexibilité, vous avez la possibilité de passer librement du superéthanol E85 à tout autre carburant essence (SP95, SP98 ou SP95-E10) et ce, à tout moment.",
            "https://www.jaguar.fr/flexfuel/index.html",
            "Démarrez votre voyage avec Jaguar",
          ],
          vehicles: [
            {
              name: "E-Pace",
              picture: "/jaguar/E85_E-PACE.jpg",
              link1: "https://www.jaguar.fr/flexfuel/index.html",
              link2:
                "https://www2.contact-jaguar.fr/demande-de-cotation-2/e-pace?model=x540&formCode=jag-requestquote-fr-fr&variant=flexfuel&tracking_source=jdx&tracking_medium=external-link&tracking_campaign=JDX_quote&tracking_content=quizz-flexfuel",
            },
          ],
        },
      ],
      Quiz: [
        {
          // pro ou perso
          value: "",
          bev: [3, 4],
          phev: [2, 3],
          mhev: [1, 2],
          e85: [4, 1],
        },
        {
          // km journaliers
          value: 0,
          bev: [4, 4, 1, 0],
          phev: [3, 1, 2, 0],
          mhev: [1, 2, 4, 0],
          e85: [2, 3, 3, 0],
        },
        {
          // accès point recharge électrique
          value: "",
          bev: [4, -200],
          phev: [3, 2],
          mhev: [1, 4],
          e85: [1, 4],
        },
        {
          // crit'Air
          value: "",
          bev: [4, 0],
          phev: [3, 0],
          mhev: [1, 0],
          e85: [2, 0],
        },
        {
          // Autoroute
          value: "",
          bev: [1, 0],
          phev: [2, 0],
          mhev: [4, 0],
          e85: [3, 0],
        },
        {
          // Remorquage
          value: "",
          bev: [1, 0],
          phev: [2, 0],
          mhev: [4, 0],
          e85: [3, 0],
        },
      ],
    };
  },
  methods: {
    start() {
      this.step = 1;
    },
    verifError(val) {
      if (val !== "") {
        this.notif.error = 0;
        return true;
      } else {
        this.notif.error = 1;
        return false;
      }
    },
    nextStep(val) {
      val = val - 1;
      if (this.verifError(this.Quiz[val].value) == true) {
        this.step++;
      }
    },
    prevStep() {
      this.step--;
    },
    retry() {
      this.Quiz.forEach((element) => {
        element.value = "";
      });
      this.Quiz[1].value = 0;
      this.step = 1;
    },
    calcul(val) {
      val = val - 1;
      if (this.verifError(this.Quiz[val].value) == true) {
        this.overlay = 1;
        this.step = 7;
        let a = this.reponses;
        let totaux = [
          this.Quiz[0].bev[this.Quiz[0].value] +
            this.Quiz[1].bev[this.Quiz[1].value] +
            this.Quiz[2].bev[this.Quiz[2].value] +
            this.Quiz[3].bev[this.Quiz[3].value] +
            this.Quiz[4].bev[this.Quiz[4].value] +
            this.Quiz[5].bev[this.Quiz[5].value],
          this.Quiz[0].phev[this.Quiz[0].value] +
            this.Quiz[1].phev[this.Quiz[1].value] +
            this.Quiz[2].phev[this.Quiz[2].value] +
            this.Quiz[3].phev[this.Quiz[3].value] +
            this.Quiz[4].phev[this.Quiz[4].value] +
            this.Quiz[5].phev[this.Quiz[5].value],
          this.Quiz[0].mhev[this.Quiz[0].value] +
            this.Quiz[1].mhev[this.Quiz[1].value] +
            this.Quiz[2].mhev[this.Quiz[2].value] +
            this.Quiz[3].mhev[this.Quiz[3].value] +
            this.Quiz[4].mhev[this.Quiz[4].value] +
            this.Quiz[5].mhev[this.Quiz[5].value],
          this.Quiz[0].e85[this.Quiz[0].value] +
            this.Quiz[1].e85[this.Quiz[1].value] +
            this.Quiz[2].e85[this.Quiz[2].value] +
            this.Quiz[3].e85[this.Quiz[3].value] +
            this.Quiz[4].e85[this.Quiz[4].value] +
            this.Quiz[5].e85[this.Quiz[5].value],
        ];
        let rep1 = Math.max(totaux[0], totaux[1], totaux[2], totaux[3]);
        let indice1 = totaux.findIndex((total) => total === rep1);
        this.best1 = indice1;
        let cloneTotaux = totaux.slice();
        cloneTotaux.splice(indice1, 1, 0);

        let rep2 = Math.max(
          cloneTotaux[0],
          cloneTotaux[1],
          cloneTotaux[2],
          cloneTotaux[3]
        );
        let indice2 = cloneTotaux.findIndex((total) => total === rep2);
        this.best2 = indice2;
        a.splice(0, 1, totaux[0]);
        a.splice(1, 1, totaux[1]);
        a.splice(2, 1, totaux[2]);
        a.splice(3, 1, totaux[3]);

        this.$parent.reco.splice(0, 1, indice1);
        this.$parent.reco.splice(1, 1, indice2);
      }
    },
  },
  computed: {
    indicator() {
      let a = (this.step / this.totauxSteps) * 100;
      return "width: " + a + "%";
    },
  },
};
</script>
<style scoped>
.msg {
  font-size: 1em;
  text-transform: uppercase;
  margin: 0;
  padding-top: 0;
  padding-bottom: 1rem;
}
.msg:not(.hide) {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.step .row {
  width: 100%;
}
</style>